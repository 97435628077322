import React from 'react';
import { Stack } from '@mui/material';

import {
  BooleanInput, FormTab, ReferenceInput, SelectInput, TabbedForm, TextInput,
} from 'react-admin';

const EditFormInner = () => (
  <TabbedForm>
    <FormTab label="General Settings">
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput source="email" />
        <TextInput source="name" />
        <ReferenceInput source="eventId" reference="events">
          <SelectInput />
        </ReferenceInput>
        <BooleanInput source="used" />
        <BooleanInput source="sent" />
      </Stack>
    </FormTab>
  </TabbedForm>
);

export default EditFormInner;
