import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, AuthRoute } from 'providers/AuthProvider';
import AdminContainer from 'containers/AdminContainer';

const routes = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        {/* <Route path="/app/*" element={}> */}
        <Route path="/app/*" element={<AuthRoute><AdminContainer /></AuthRoute>} />
        {/* </Route> */}
        <Route path="*" element={<Navigate to="/app" />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthProvider>
  </BrowserRouter>
);

export default routes;
