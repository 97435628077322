import React from 'react';
import { ErrorBoundary } from 'utils/errorBoundary';

export const withErrorBoundary = (ComponentToWrap) => {
  const WrappedComponent = (props) => (
    <ErrorBoundary>
      <ComponentToWrap {...props} />
    </ErrorBoundary>
  );

  return WrappedComponent;
};
