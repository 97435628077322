import React, { useCallback } from 'react';
import { Button, useRecordContext, useShowContext } from 'react-admin';
import { sendTicket } from 'utils/api';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const TicketSendButton = ({ id: propsTicketId, sent: propsSent }) => {
  const { id: recordTicketId, sent: recordSent } = useRecordContext();
  const { record } = useShowContext();
  const showTicketId = record?.id;
  const showSent = record?.sent;
  const ticketId = propsTicketId || showTicketId || recordTicketId;
  const ticketSent = propsSent || showSent || recordSent;
  const handleClick = useCallback(async () => {
    if (window.confirm('Send ticket?')) {
      await sendTicket(ticketId);
    }
  }, []);

  if (ticketSent) {
    return <p>TICKET ALREADY SENT</p>;
  }

  if (!ticketId) {
    return <Spin />;
  }
  return (
    <Button
      onClick={handleClick}
    >
      SEND TICKET
    </Button>
  );
};

TicketSendButton.propTypes = {
  id: PropTypes.string.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default TicketSendButton;
