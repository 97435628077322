import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ZoomContext = createContext({
  factor: 1,
  setFactor: () => {},
});

export const ZoomProvider = ({ children, initialFactor }) => {
  const [previewFactor, setPreviewFactor] = useState(initialFactor);
  const [editFactor, setEditFactor] = useState(initialFactor);

  const zoomCtx = useMemo(() => ({
    previewFactor,
    setPreviewFactor,
    editFactor,
    setEditFactor,
  }), [
    previewFactor,
    setPreviewFactor,
    editFactor,
    setEditFactor,
  ]);
  return (
    <ZoomContext.Provider
      value={zoomCtx}
    >
      {children}
    </ZoomContext.Provider>
  );
};

ZoomProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialFactor: PropTypes.any.isRequired,
};

export const useZoomContext = () => useContext(ZoomContext);
