import React from 'react';
import {
  BooleanField, Datagrid, EditButton, List, ShowButton, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const EventList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="name" label="Name" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default EventList;
