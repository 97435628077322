import React, { useMemo } from 'react';

import {
  Admin,
  Resource,
  CustomRoutes,
  fetchUtils,
} from 'react-admin';
import { Route } from 'react-router';
import './AdminContainer.scss';
import { useAuthContext, usePermissions } from 'providers/AuthProvider';
import jsonServerProvider from 'ra-data-json-server';

import { getTicketApiUrl } from 'utils/api';
import TicketList from 'components/resources/Ticket/List';
import TicketShow from 'components/resources/Ticket/Show';
import TicketCreate from 'components/resources/Ticket/Create';
import TicketEdit from 'components/resources/Ticket/Edit';

import EventList from 'components/resources/Event/List';
import EventShow from 'components/resources/Event/Show';
import EventCreate from 'components/resources/Event/Create';
import EventEdit from 'components/resources/Event/Edit';

import { Alert } from '@mui/material';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new window.Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(getTicketApiUrl(), httpClient);

const AdminContainer = () => {
  const authCtx = useAuthContext();

  const permissions = usePermissions();

  if (!permissions.length) {
    return (
      <Alert>Forbidden</Alert>
    );
  }

  const AvailableComponents = useMemo(() => [
    { component: TicketList, permissions: ['view'], key: 'TicketList' },
    { component: TicketShow, permissions: ['view'], key: 'TicketShow' },
    { component: TicketCreate, permissions: ['view', 'edit'], key: 'TicketCreate' },
    { component: TicketEdit, permissions: ['view', 'edit'], key: 'TicketEdit' },
    { component: EventList, permissions: ['view'], key: 'EventList' },
    { component: EventShow, permissions: ['view'], key: 'EventShow' },
    { component: EventCreate, permissions: ['view', 'edit'], key: 'EventCreate' },
    { component: EventEdit, permissions: ['view', 'edit'], key: 'EventEdit' },
  ].reduce((acc, item) => {
    const allowed = item.permissions.every((permissionItem) => permissions.includes(permissionItem));
    if (!allowed) {
      return acc;
    }
    return {
      ...acc,
      [item.key]: item.component,
    };
  }, {}), [permissions]);

  return (
    <Admin
      basename="/app"
      authProvider={authCtx.raAuthProvider}
      dataProvider={dataProvider}
    >
      <Resource
        name="ticket"
        list={AvailableComponents.TicketList}
        show={AvailableComponents.TicketShow}
        create={AvailableComponents.TicketCreate}
        edit={AvailableComponents.TicketEdit}
      />
      <Resource
        name="events"
        list={AvailableComponents.EventList}
        show={AvailableComponents.EventShow}
        create={AvailableComponents.EventCreate}
        edit={AvailableComponents.EventEdit}
      />
      <CustomRoutes>
        <Route path="/my-profile" element={<div>My Profile</div>} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminContainer;
