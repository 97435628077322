import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getMe, TOKEN_KEY } from 'utils/api';
import qs from 'query-string';
import { Alert, Layout, Spin } from 'antd';
import wildcard from 'wildcard';

const authFrontendUrl = window?.config?.urlAuth || process.env.REACT_APP_URL_AUTH;
const authLogoutUrl = `${authFrontendUrl}/logout`;
const authRedirectUrl = `${authFrontendUrl}/login`;

export const OPERATION_AND = 'OPERATION_AND';
export const OPERATION_OR = 'OPERATION_OR';

const parseToken = (location) => {
  try {
    const parsed = qs.parse(location.search);
    return parsed.token;
  } catch (e) {
    return null;
  }
};

const reactAdminAuthProvider = () => ({
  async setAuthCtx(authCtx) {
    this.authCtx = authCtx;
  },
  async logout() {
    await this.authCtx.logout();
  },
  async checkError() {
    return Promise.resolve();
  },
  async checkAuth() {
    return this.authCtx.user ? Promise.resolve() : Promise.reject();
  },
  async getPermissions() {
    const permissions = this.authCtx?.permissions;
    return Promise.resolve(permissions);
  },
  async getIdentity() {
    const user = this.authCtx?.user;
    return Promise.resolve(user);
  },
});

const AuthContext = createContext({
  token: null,
  user: null,
  loaded: false,
  loading: true,
  permissions: [],
  load: () => {},
  logout: () => {},
  // eslint-disable-next-line no-unused-vars
  checkPermissions: (_permissionsList, _operation) => {},
  // eslint-disable-next-line no-unused-vars
  checkPermission: (_permission) => {},
});

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [raAuthProvider] = useState(() => reactAdminAuthProvider());
  const [token, setToken] = useState(() => localStorage.getItem(TOKEN_KEY));
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const permissions = user?.permissions || [];

  const handleSetToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem(TOKEN_KEY, newToken);
  };

  const handleLoadAuth = async () => {
    try {
      const newTokenFromUrl = parseToken(location);
      if (newTokenFromUrl && newTokenFromUrl !== token) {
        navigate(location.pathname, { replace: true });
        handleSetToken(newTokenFromUrl);
        return;
      }
      if (newTokenFromUrl && newTokenFromUrl === token) {
        navigate(location.pathname, { replace: true });
      }
      if (!token) {
        setUser(null);
        setLoaded(true);
        setLoading(false);

        const redirectUrlWithCallback = `${authRedirectUrl}?callback_url=${window.location.href}`;
        window.location.href = redirectUrlWithCallback;
        return;
      }
      setLoading(true);
      const newUser = await getMe();
      setUser(newUser.user);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  const handleLogout = () => {
    localStorage.setItem(TOKEN_KEY, null);
    window.location.href = `${authLogoutUrl}?callback_url=${window.location.href}`;
  };

  const handleCheckPermission = useCallback((permissionToCheck) => (
    permissions.some((allowedPermission) => wildcard(allowedPermission, permissionToCheck))
  ), [permissions]);

  const handleCheckPermissions = useCallback((permissionsList, operation) => {
    switch (operation) {
      case OPERATION_AND: return (
        permissionsList.every((item) => handleCheckPermission(item))
      );
      case OPERATION_OR: return (
        permissionsList.some((item) => handleCheckPermission(item))
      );
      default: throw new Error(`Unsupported operation: ${operation}`);
    }
  }, [handleCheckPermission]);

  useEffect(() => {
    handleLoadAuth();
  }, [token]);

  const authCtx = useMemo(() => ({
    token,
    user,
    loaded,
    loading,
    permissions,
    raAuthProvider,
    load: handleLoadAuth,
    logout: handleLogout,
    checkPermissions: handleCheckPermissions,
    checkPermission: handleCheckPermission,
  }), [
    token,
    user,
    loaded,
    loading,
  ]);

  raAuthProvider.setAuthCtx(authCtx);

  return (
    <AuthContext.Provider
      value={authCtx}
    >
      {!loading && children}
      {loading && <Spin /> }
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => useContext(AuthContext);

// eslint-disable-next-line complexity
export const AuthRoute = ({ permissions, operation, fallbackUrl, fallbackTxt, children }) => {
  const authCtx = useAuthContext();

  const loading = !!authCtx.loading;

  if (loading) {
    return <Spin />;
  }

  const authorized = !!authCtx.user;
  const allowed = (permissions && operation && authorized)
    ? authCtx.checkPermissions(permissions, operation)
    : authorized;

  if (!allowed) {
    switch (true) {
      case !!fallbackUrl: return <Navigate to={fallbackUrl} />;
      case !!fallbackTxt: return <Layout><Alert message={fallbackTxt} /></Layout>;
      default: return <Layout><Alert message={authorized ? 'Forbidden' : 'Unauthorized'} /></Layout>;
    }
  }
  return children;
};

AuthRoute.defaultProps = {
  permissions: null,
  operation: null,
  fallbackTxt: null,
  fallbackUrl: null,
  children: null,
};

AuthRoute.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  operation: PropTypes.oneOf([OPERATION_AND, OPERATION_OR]),
  fallbackTxt: PropTypes.string,
  fallbackUrl: PropTypes.string,
  children: PropTypes.any,
};

const PERMISSIONS = [
  { role: 'SUPER_ADMIN', permissions: ['edit', 'view'] },
  { role: 'USER', permissions: ['edit', 'view'] },
];

export const usePermissions = () => {
  const authCtx = useAuthContext();
  const userRole = authCtx?.user?.role;
  const permissionsItem = PERMISSIONS.find(({ role }) => role === userRole);
  const permissionsList = permissionsItem?.permissions || [];
  return permissionsList;
};
