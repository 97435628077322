import React from 'react';
import {
  BooleanField, ChipField, Datagrid, EditButton, List, ReferenceField, ShowButton, TextField,
} from 'react-admin';

import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';
import TicketSendButton from 'components/resources/Ticket/TicketSendButton';

const TicketList = () => (
  <List>
    <Datagrid>
      <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
      <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
      <TextField source="name" label="Name" />
      <TextField source="email" label="Email" />
      <ReferenceField source="eventId" reference="events">
        <ChipField source="name" />
      </ReferenceField>
      <BooleanField source="used" label="Used" />
      <BooleanField source="sent" label="Sent" />
      <EditButton />
      <ShowButton />
      <TicketSendButton />
    </Datagrid>
  </List>
);

export default TicketList;
