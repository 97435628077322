import React from 'react';
import {
  BooleanField, ChipField, ReferenceField, Show, SimpleShowLayout, TextField,
} from 'react-admin';

import { Card, CardHeader, Stack } from '@mui/material';
import DateFieldUnixtime from 'components/fields/DateFieldUnixtime/DateFieldUnixtime';

const TicketShow = () => (
  <Show>
    <Stack p={2} spacing={2}>
      <Card raised>
        <CardHeader>
          Ticket
        </CardHeader>
        <Stack direction="row" spacing={2}>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampUpdated" label="Updated at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <DateFieldUnixtime source="timestampCreated" label="Created at" showTime />
          </SimpleShowLayout>
          <SimpleShowLayout>
            <TextField source="name" label="Name" />
          </SimpleShowLayout>
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} p={2}>
        <Stack spacing={2} maxWidth="250px">
          <h3>General</h3>
          <SimpleShowLayout>
            <TextField source="email" label="Email" />
            <ReferenceField source="eventId" reference="events">
              <ChipField source="name" />
            </ReferenceField>
            <BooleanField source="used" label="Used" />
            <BooleanField source="sent" label="Sent" />
          </SimpleShowLayout>
        </Stack>
      </Stack>
    </Stack>
  </Show>
);

export default TicketShow;
